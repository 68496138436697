import Vue from "vue";
import Vuex from 'vuex'
Vue.use(Vuex)
//去重对象数组函数
//store
const store = new Vuex.Store({
    state: {
        systemTitle:'银河药业网上商城，网上买药，药品批发，购药网站',
        version:'1.0',
        basicParams:{
            device_type:2
        },//接口公共参数
        loginRes:{},//用户登录返回的信息
        tel:null,//客服电话
        submitOrderParams:null
    },
    getters: {
        // systemTitle(state){
        //     return state
        // }
    },
    mutations: {
        //state数据初始化
        stateInit(state){
            state.loginRes={}//用户登录返回的信息
            state.basicParams= {device_type:2}
        },
        //用户信息
        loginRes (state,newval) {
            state.loginRes=newval
        },
        //接口公共参数
        basicParams (state,newval) {
            state.basicParams=newval
        },
        //客服电话
        tel (state,newval) {
            state.tel=newval
        },
        //客服电话
        submitOrderParams (state,newval) {
            state.submitOrderParams=newval
        },
    },
    actions: {
       
    }
})

export default store