//加密方法
import CryptoJS from "crypto-js";
const key=CryptoJS.enc.Utf8.parse('1234567890000000');//16位
const iv=CryptoJS.enc.Utf8.parse('1234567890000000');
export default{
    //aes加密
    encrypt(word){
        let encrypted="";
        if(typeof word=="object"){
            //对象格式的转换成json字符串
            const data=JSON.stringify(word);
            const srcs=CryptoJS.enc.Utf8.parse(data);
            encrypted=CryptoJS.AES.encrypt(srcs,key,{
                iv:iv,
                mode:CryptoJS.mode.CBC,
                padding:CryptoJS.pad.Pkcs7
            });
        }else{
            const srcs=CryptoJS.enc.Utf8.parse(word);
            encrypted=CryptoJS.AES.encrypt(srcs,key,{
                iv:iv,
                mode:CryptoJS.mode.CBC,
                padding:CryptoJS.pad.Pkcs7
            });
        }
        return encrypted.ciphertext.toString();
    },
    //AES解密
    decrypt(word){
        const encryptedHexStr=CryptoJS.enc.Hex.parse(word);
        const srcs=CryptoJS.enc.Base64.stringify(encryptedHexStr);
        const decrypt=CryptoJS.AES.decrypt(srcs,key,{
            iv:iv,
            mode:CryptoJS.mode.CBC,
            padding:CryptoJS.pad.Pkcs7
        });
        const decryptedSrt=decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedSrt.toString();
    },
    md5(str){
        let result = CryptoJS.MD5(str).toString();
        return result;
    },
    rbit16(){
        var key = [0,1,2,3,4,5,6,7,8,9,
                'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z',
            'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
        var result = '';
        for(var i = 0;i < 16;i++){
            result = result + key[Math.floor(Math.random() * key.length)]
        }
        return result;
    }
}