
const isSameDay=function(startTime, endTime) {
  const startTimeMs = new Date(startTime).setHours(0,0,0,0);
  const endTimeMs = new Date(endTime).setHours(0,0,0,0);
  return startTimeMs === endTimeMs ? true : false
}
const timeInterval=function(startTime, endTime) {
 
  return (endTime-startTime)/1000
}
export{
  isSameDay,
  timeInterval
} 