import Vue from "vue";
import VueRouter from "vue-router";
//解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错的问题
const originalPush=VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

import store from '../store'
import crypto from "../common/crypto";
import {MessageBox } from 'element-ui'
import {timeInterval} from "../common/timeCompare";
const routes = [
    {   
        path:'/',
        name:'root',
        redirect: '/default',
    },
    {   
        path:'/default',
        name:'default',
        redirect: '/mall',
        component: () => import('../view/Default.vue'),
        children:[
            {
                path:'/mall', 
                redirect: '/mall/home',
                name:'mall',
                component: () => import('../view/mall/Entry.vue'),
                children: [
                    {
                        path:'home',//首页
                        name:'home',
                        meta:{keepalive:false},
                        component: () => import('../view/mall/Home.vue'),
                    },
                    {
                        path:'prodList',//商品列表
                        name:'prodList',
                        meta:{keepalive:false},
                        component: () => import('../view/mall/ProdList.vue'),
                    },
                    {
                        path:'prodDetail',//商品详情
                        name:'prodDetail',
                        meta:{loginAuth:true,realAuth:true},
                        component: () => import('../view/mall/ProdDetail.vue'),
                    },
                    {
                        path:'articleList',//公告列表
                        name:'articleList',
                        meta:{keepalive:false},
                        component: () => import('../view/mall/ArticleList.vue'),
                    },
                    {
                        path:'articleDetail',//文章详情
                        name:'articleDetail',
                        component: () => import('../view/mall/ArticleDetail.vue'),
                    },
                    {
                        path:'/noAuth',
                        name:'noauth',
                        component: () => import('../view/NoAuth.vue')
                    },
                    {
                        path:'/error',
                        name:'error',
                        component: () => import('../view/Error.vue')
                    },
                    
                ]
            },
            {
                path:'/user', 
                redirect: '/user/home',
                name:'user',
                meta:{loginAuth:true},
                component: () => import('../view/user/Entry.vue'),
                children: [
                    {
                        path:'home',//个人中心
                        name:'userHome',
                        meta:{title:'个人中心'},
                        component: () => import('../view/user/Home.vue'),
                    },
                    {
                        path:'order',//个人中心
                        name:'order',
                        meta:{title:'我的订单'},
                        component: () => import('../view/user/OrderList.vue'),
                    },
                    {
                        path:'collect',//我的收藏
                        name:'collect',
                        meta:{title:'我的收藏'},
                        component: () => import('../view/user/CollectList.vue'),
                    },
                    {
                        path:'address',
                        name:'address',
                        meta:{title:'地址管理'},
                        component: () => import('../view/user/AddressList.vue'),
                    },
                    {
                        path:'browsingHistory',
                        name:'browsingHistory',
                        meta:{title:'浏览记录'},
                        component: () => import('../view/user/BrowsingHistory.vue'),
                    },
                ]
            },
            {
                path:'/orderDetail',
                name:'orderDetail',
                meta:{loginAuth:true,title:'订单详情'},
                component: () => import('../view/user/OrderDetail.vue'),
            },
            {
                path:'/shoppingCart',//购物车
                name:'shoppingCart',
                meta:{loginAuth:true,realAuth:true,title:'购物车'},
                component: () => import('../view/shopping/ShoppingCart.vue'),
            },
            {
                path:'/submitOrder',//结算
                name:'submitOrder',
                meta:{loginAuth:true,realAuth:true,title:'结算'},
                component: () => import('../view/shopping/SubmitOrder.vue'),
            },
            {
                path:'/login',
                name:'login',
                component: () => import('../view/Login.vue')
            },
        ]
    },
    

    {
        path:'/redirect',
        name:'redirect',
        component: () => import('../view/Redirect.vue')
    },
    {   
        //浏览器版本低提示
        path:'/browserVersionPrompt',
        name:'browserVersionPrompt',
        component: () => import('../view/BrowserVersionPrompt.vue')
    },
    {
        path:'*',
        name:'404',
        component: () => import( '../view/404.vue')
    }
]

const router = new VueRouter({
    // mode: 'history',   // 模式，默认hash
    // base: '/',
    routes
})
const styleObj = document.documentElement.style;
router.beforeEach((to, from, next) => {
    document.body.scrollTop=document.documentElement.scrollTop=0
    //从缓存取登录信息
    if(!store.state.loginRes.success){
        try{    
            let loginRes=JSON.parse(crypto.decrypt(localStorage.getItem('loginRes')))   
            let loginStamp=localStorage.getItem('loginStamp')
            if(loginStamp&&loginRes&&timeInterval(Number(loginStamp),new Date().getTime())/3600<24){
                store.commit('loginRes',loginRes)
            }else{
                localStorage.removeItem('loginRes')
                localStorage.removeItem('loginStamp')
            }
        }catch{
           //
        }
    }
    //判断浏览器是否支持html5
    if ((!('flex' in styleObj)||!('flexWrap' in styleObj)||!document.body.getBoundingClientRect())&&to.name!='browserVersionPrompt') {
        next({
            path: '/browserVersionPrompt',
        })
    }
    //如未登录，需授权登录的页面跳转登录页
    else if(to.matched.some(record => record.meta.loginAuth)&&!store.state.loginRes.success){
        next({
            path: '/login',
            query:{
                redirect:to.fullPath
            }
        })
    }
    //如未身份审核，需授权登录的页面跳转登录页
    else if(to.matched.some(record => record.meta.realAuth)&&store.state.loginRes.audit_state!=2){
        MessageBox.confirm (store.state.loginRes.audit_state==0?'您还未提交资料审核，此功能受限！':'您提交的资料正在审核中，此功能受限！','审核提示', {
            customClass:"custom-msgbox",
            confirmButtonText: store.state.loginRes.audit_state==0?'提交资料':'知道了',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            if(store.state.loginRes.audit_state==0){
                next({
                    path: '/user',
                })
            }else{
                if(window.history.length==1){
                    window.close()
                }
            }
          })
          .catch(() => {
            if(window.history.length==1){
                window.close()
            }
          });
    }
    else{
        next()
    }
  })

export default router;