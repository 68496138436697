
const defaultChartOption = {
    color:["#3870ff","#28C4AC","#FFB15C","#F94F52","#766AC8","#B77FD6","#7BB85B","#FB8F4F","#1AB687","#3EA9F9","#EE82C2","#D8527D","#838A94","#274FB3","#1C8A79","#B37C41","#AF373A","#534A8C","#805996","#568140","#B06437","#12805F","#2C77AF","#A75B88","#983A58","#5C6168","#88A9FF","#7EDCCD","#FFD09D","#FB9597","#ADA6DE","#D4B2E6","#B0D49D","#FDBC95","#76D3B7","#8BCBFB","#F5B4DA","#E897B1","#B5B9BF"],
    grid:{
      left:10,
      right:30,
      top:30,
      bottom:0,
      containLabel: true ,
    },
    toolbox: {
      show: true,
      feature: {
        // dataZoom: {
        //   yAxisIndex: "none"
        // },
        // restore: {},
        
        // saveAsImage: {
        //   name:"卓创价格预测",
        //   title: '保存为图片',
        //   excludeComponents: ['toolbox','dataZoom'],
        //   pixelRatio:2
        // }
      },
      emphasis: {
        iconStyle: {         
          borderColor:"#11bf99",
          textFill:"#999"
        }
      }
    },
    xAxis: {
        type: 'category',
        name:'',
        nameLocation: 'end' ,
        nameGap: 5,
        nameTextStyle:{
          align:'left'
        },
        splitLine: {
          lineStyle: {
            // color:'#d4d4d4',
            type:'dashed'
          }
        },
        scale:true,
        axisLine: {
          lineStyle:{
            // color: '#d4d4d4',
          }
        },
        axisLabel:{
          // color:'#a4a4a4',
        }
    },
    yAxis: {
        type: 'value',
        name:'',
        nameLocation: 'end' ,
        nameGap: 5,
        nameTextStyle:{
          align:'left'
        },
        splitLine: {
          lineStyle: {
            // color:'#d4d4d4',
            type:'dashed'
          }
        },
        scale:true,
        axisLine: {
          lineStyle:{
            // color: '#d4d4d4',
          }
        },
        axisLabel:{
          // color:'#a4a4a4',
        }
    },
    legend:{
        bottom:0, 
        itemWidth: 14 ,
        tooltip: {
            show: false
        },
        data:[]
    },
    dataZoom:{
        type:'slider',
        height: 10,//这里可以设置dataZoom的尺寸    
        handleIcon:'path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5C17.6,3.5,6.8,14.4,6.8,27.6c0,13.3,10.8,24.1,24.101,24.1C44.2,51.7,55,40.9,55,27.6C54.9,14.4,44.1,3.5,30.9,3.5z M36.9,35.8c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H36c0.5,0,0.9,0.4,0.9,1V35.8z M27.8,35.8 c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H27c0.5,0,0.9,0.4,0.9,1L27.8,35.8L27.8,35.8z',  
        handleSize: '100%' ,
        handleStyle:{
          opacity:0.5
        },
        brushSelect: false ,
        bottom: 0 ,
        show:false
    },
    tooltip:{
      trigger: 'axis' ,
      confine:true,
      axisPointer: {
        type: 'line',
        label: {
          backgroundColor:'rgba(0, 0, 0, 0.4)'
        }
      },
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    series: [] ,
    backgroundColor:'transparent'
};
export default defaultChartOption